import axios from 'axios'
import config from './config'

const API_URL =  config.url + 'AcessoCliente/' 

const headers = {  
  'Content-Type': 'application/json;charset=UTF-8',
  //'Access-Control-Allow-Origin': 'http://localhost:8080',
 // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  //'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'

  }




class AuthService {
  login (data) {
    
    return axios
      .post(API_URL + 'Autenticar', data, {
        headers: headers
      })
      .then(response => {
        return response.data
      })
  }

  cadastro (data) {
    return axios
      .post(API_URL + 'Cadastrar', data, {
        headers: headers
      })
      .then(response => {
        return response.data
      })
  }

  recuperarSenha (data) {
    return axios
      .post(API_URL + 'RecuperarSenha/', data, {
        headers: headers
      })
      .then(response => {
        return response.data
      })
  }


  


  logout () {
    sessionStorage.removeItem('acesso')
  }

  register (user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password,
    })
  }
}

export default new AuthService()
